import React from "react"

function Footer() {
  return (
    <footer
      id="footerContact"
      className="footer relative bg-transparent relative  "
    >
      <div className="container mx-auto px-6">
        <div className="mt-5  flex flex-col ">
          <div className="sm:w-2/3 py-3">
            <p className="text-sm text-gray-300 font-semibold ">
              © 2022 by Antaryami
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
