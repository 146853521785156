import React from 'react';
import Footer from './footer';
import Sidebar from "./sidebar";

function Layout({route,children}) {
  return (
  <>
  <Sidebar route={route} />
  <main>{children}</main>
  <Footer/>
  </>);
}

export default Layout;
